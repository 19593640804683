import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
 
  {
    path: 'dashboard',
    loadChildren: () => import('./objects/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'material/create',
    loadChildren: () => import('./objects/material/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'material/home-material',
    loadChildren: () => import('./objects/material/home-material/home-material.module').then(m => m.HomeMaterialPageModule)
  },
  {
    path: 'article/home-article',
    loadChildren: () => import('./objects/article/home-article/home-article.module').then(m => m.HomeArticlePageModule)
  },
  {
    path: 'article/create',
    loadChildren: () => import('./objects/article/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'customer/create',
    loadChildren: () => import('./objects/customer/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'customer/home-customer',
    loadChildren: () => import('./objects/customer/home-customer/home-customer.module').then(m => m.HomeCustomerPageModule)
  },
  {
    path: 'vendor/home-vendor',
    loadChildren: () => import('./objects/vendor/home-vendor/home-vendor.module').then(m => m.HomeVendorPageModule)
  },
  {
    path: 'vendor/create',
    loadChildren: () => import('./objects/vendor/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'article-hierarchy/create',
    loadChildren: () => import('./objects/articleHierarchy/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'article-hierarchy/home-article-hierarchy',
    loadChildren: () => import('./objects/articleHierarchy/home-article-hierarchy/home-article-hierarchy.module').then(m => m.HomeArticleHierarchyPageModule)
  },
  {
    path: 'gl/home-gl',
    loadChildren: () => import('./objects/gl/home-gl/home-gl.module').then(m => m.HomeGlPageModule)
  },
  {
    path: 'routing/home-routing',
    loadChildren: () => import('./objects/routing/home-routing/home-routing.module').then(m => m.HomeRoutingPageModule)
  },
  {
    path: 'bom/home-bom',
    loadChildren: () => import('./objects/bom/home-bom/home-bom.module').then(m => m.HomeBomPageModule)
  },
  {
    path: 'gl/create',
    loadChildren: () => import('./objects/gl/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'profit-center/home-profit-center',
    loadChildren: () => import('./objects/profitCenter/home-profit-center/home-profit-center.module').then(m => m.HomeProfitCenterPageModule)
  },
  {
    path: 'profit-center/create',
    loadChildren: () => import('./objects/profitCenter/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'supplier-portal/dashboard',
    loadChildren: () => import('./supplier-portal/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'supplier-portal/vendor-request',
    loadChildren: () => import('./supplier-portal/vendor-request/vendor-request.module').then(m => m.VendorRequestPageModule)
  },
  {
    path: 'material-modal',
    loadChildren: () => import('./modals/material-modal/material-modal.module').then(m => m.MaterialModalPageModule)
  },
  {
    path: 'routing/create',
    loadChildren: () => import('./objects/routing/create/create.module').then(m => m.CreatePageModule)
  },
  {
    path: 'costCenter/home-cost-center',
    loadChildren: () => import('./objects/costCenter/home-cost-center/home-cost-center.module').then(m => m.HomeCostCenterPageModule)
  },
  {
    path: 'costCenter/create',
    loadChildren: () => import('./objects/costCenter/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'salesOrder/create',
    loadChildren: () => import('./transactional-data/sales-order/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'salesOrder/home-sales-order',
    loadChildren: () => import('./transactional-data/sales-order/home-sales-order/home-sales-order.module').then( m => m.HomeSalesOrderPageModule)
  },
  {
    path: 'settings/workflow-setup',
    loadChildren: () => import('./settings/workflow-setup/workflow-setup.module').then( m => m.WorkflowSetupPageModule)
  },
  {
    path: 'settings/user-setup',
    loadChildren: () => import('./settings/user-setup/user-setup.module').then( m => m.UserSetupPageModule)
  },
  {
    path: 'settings/user-groups',
    loadChildren: () => import('./settings/user-groups/user-groups.module').then( m => m.UserGroupsPageModule)
  },
  {
    path: 'settings/add-user-group/:id',
    loadChildren: () => import('./settings/add-user-group/add-user-group.module').then( m => m.AddUserGroupPageModule)
  },
  {
    path: 'salesOrderStaging/home-sales-order-staging',
    loadChildren: () => import('./transactional-data/sales-order-staging/home-sales-order-staging/home-sales-order-staging.module').then( m => m.HomeSalesOrderStagingPageModule)
  },
  {
    path: 'salesOrderStaging/create',
    loadChildren: () => import('./transactional-data/sales-order-staging/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path : 'supplier-portal/vendor-registration/:id',
    loadChildren : () => import('./supplier-portal/vendor-registration/vendor-registration.module').then(m=>m.VendorRegistrationPageModule)
  },
  {
    path : 'supplier-portal/vendor-update-request',
    loadChildren : () => import('./supplier-portal/vendor-update-request/vendor-update-request.module').then(m=>m.VendorUpdateRequestPageModule)
  },
  {
    path : 'supplier-portal/vendor-unblock',
    loadChildren : () => import('./supplier-portal/vendor-unblock/vendor-unblock.module').then(m=>m.VendorUnblockPageModule)
  },
  {
    path : 'supplier-portal/vendor-update-request',
    loadChildren : () => import('./supplier-portal/vendor-update-request/vendor-update-request.module').then(m=>m.VendorUpdateRequestPageModule)
  },
  {
    path : 'supplier-portal/carrier-vendor-request',
    loadChildren : () => import('./supplier-portal/carrier-vendor-request/carrier-vendor-request.module').then(m=>m.CarrierVendorRequestPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./authentication/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'site/home-site',
    loadChildren: () => import('./objects/site/home-site/home-site.module').then( m => m.HomeSitePageModule)
  },
  {
    path: 'site/create',
    loadChildren: () => import('./objects/site/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./authentication/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'bom/create',
    loadChildren: () => import('./objects/bom/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'view-request',
    loadChildren: () => import('./components/view-request/view-request.module').then( m => m.ViewRequestPageModule)
  },
  {
    path: 'add-user/:id',
    loadChildren: () => import('./settings/add-user/add-user.module').then( m => m.AddUserPageModule)
  },
  {
    path: 'mass-upload-info',
    loadChildren: () => import('./components/mass-upload-info/mass-upload-info.module').then( m => m.MassUploadInfoPageModule)
  },
  {
    path: 'article-hierarchy-modal',
    loadChildren: () => import('./components/article-hierarchy-modal/article-hierarchy-modal.module').then( m => m.ArticleHierarchyModalPageModule)
  },
  {
    path: 'dmac/home',
    loadChildren: () => import('./dmac/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'material/create-extension',
    loadChildren: () => import('./objects/material/create-extension/create-extension.module').then( m => m.CreateExtensionPageModule)
  },
  {
    path: 'display-table/:primaryKey/:id',
    loadChildren: () => import('./dmac/display-table/display-table.module').then( m => m.DisplayTablePageModule)
  },
  {
    path: 'settings/control/material',
    loadChildren: () => import('./settings/control/material/material.module').then( m => m.MaterialPageModule)
  },
  {
    path: 'settings/control/home',
    loadChildren: () => import('./settings/control/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'column-splitter-modal',
    loadChildren: () => import('./components/column-splitter-modal/column-splitter-modal.module').then( m => m.ColumnSplitterModalPageModule)
  },
  {
    path: 'string-resizer-modal',
    loadChildren: () => import('./components/string-resizer-modal/string-resizer-modal.module').then( m => m.StringResizerModalPageModule)
  },
  {
    path: 'concat-column-modal',
    loadChildren: () => import('./components/concat-column-modal/concat-column-modal.module').then( m => m.ConcatColumnModalPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./authentication/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./authentication/onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    path: 'my-sqlmodal-page',
    loadChildren: () => import('./components/my-sqlmodal-page/my-sqlmodal-page.module').then( m => m.MySQLModalPagePageModule)
  },
  {
    path: 'hanacloud-modal-page',
    loadChildren: () => import('./components/hanacloud-modal-page/hanacloud-modal-page.module').then( m => m.HANACloudModalPagePageModule)
  },
  {
    path: 'settings/profile',
    loadChildren: () => import('./settings/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'display-db-table/:primaryKey/:id',
    loadChildren: () => import('./dmac/display-db-table/display-db-table.module').then( m => m.DisplayDbTablePageModule)
  },
  {
    path: 'display-hana-db-table/:primaryKey/:id',
    loadChildren: () => import('./dmac/display-hana-db-table/display-hana-db-table.module').then( m => m.DisplayHanaDbTablePageModule)
  },
  {
    path: 'db-column-splitter-modal',
    loadChildren: () => import('./components/db-column-splitter-modal/db-column-splitter-modal.module').then( m => m.DbColumnSplitterModalPageModule)
  },
  {
    path: 'db-concat-column-modal',
    loadChildren: () => import('./components/db-concat-column-modal/db-concat-column-modal.module').then( m => m.DbConcatColumnModalPageModule)
  },
  {
    path: 'db-string-resizer-modal',
    loadChildren: () => import('./components/db-string-resizer-modal/db-string-resizer-modal.module').then( m => m.DbStringResizerModalPageModule)
  },
  {
    path: 'settings/log',
    loadChildren: () => import('./settings/log/log.module').then( m => m.LogPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
