import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Rx from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  url: any = 'https://www.saphelpline.com/';
  domainAndScreenPermissions = new Rx.BehaviorSubject({});
  userDetails = new Rx.BehaviorSubject({});
  orgOnboarded = new Rx.BehaviorSubject({});
  updatePermissions = new Rx.BehaviorSubject(false);
  userGroup: any = {};
  // apiUrl: any = 'http://localhost:8182/';

  apiUrl: any = 'https://api.kmg.idmx.io/'
  // apiUrl: any = 'https://api.dev.idmx.io/'
  // apiUrl: any = 'http://192.168.1.54:8182/'; 

  geturl: string;
  posturl: string;
  userType: string;
  organisationId: string;
// oragsanisationId
  constructor(public http: HttpClient) { }

  massUpload(payload: any, data: any) {
    return this.http.post(this.apiUrl + payload, data)
  }

  fetchVariants(object) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + object, { userId: "61de8d622d5a3a48e34a8bec" }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  getUserDetails(userId) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'getUserDetails', { userId: userId }).subscribe((resp: any) => {
        console.log("getUserDetails ", resp)
        if (resp) {
          if (resp.organisationType == 'organisation'){
            this.userType = 'organisation';
            this.organisationId = resp._id;
          }
          else
          {
            this.userType = 'user';
            this.organisationId = resp.organisationId;
          }
                  console.log("this.organisationId ", this.organisationId)
          this.domainAndScreenPermissions.next(resp.domainAndScreenPermissions);
          this.userDetails.next(resp);
        }
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  organisationRegister(organisationData) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'organisationRegister', organisationData).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  organisationUpdate(organisationData) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'organisationUpdate', organisationData).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  login(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'login', data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
}
