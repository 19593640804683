import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { ScrollbarThemeDirective } from './directives/scrollbar-theme.directive';
import { FormBuilderModule } from './components/form-builder/form-builder.module';
// import { FormBuilderComponent } from './components/form-builder/form-builder.component';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ToastNoAnimationModule } from 'ngx-toastr';

@NgModule({
  declarations: [AppComponent, ScrollbarThemeDirective],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    // BrowserAnimationsModule,
    NgxDatatableModule,
    // FormBuilderModule,
    // ToastNoAnimationModule.forRoot(),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    IonicModule.forRoot({ mode: 'ios' }),
    AppRoutingModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
